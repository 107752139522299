$blue-primary: #42a5f5;
$torii-plum: #463264;
$blue-secondary: #1565c0;
$green-primary: #2cc7a4;
$green-secondary: #59b599;
$grey-primary: #2f2f2f;
$grey-light: #eaeaea;
$grey-accent: #bdbdbd;
$pink-primary: #e2658c;
$text-color: rgba(0, 0, 0, 0.87);
$text-color-light: #555555;
$text-dark: #4a4a4a;
// New Dahsboard palette
$purple-primary: #463264;
$purple-secondary: #c8bddb;
$orange-primary: #f18257;
$pink-primary: #f3a4bf;
$cream-background: #fbf5ef;
$light-text-color: #ffffff;
$dark-text-color: #323232;
$pine-green: #195a50;

$selected_button_size: 26px;
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$expandSize: 7px;

$defaultFont: 'Sharp Grotesk Semibold';
