@import '../abstracts/mixins';
@import '../abstracts/variables';

.alertDialog {
  .alertDialogCloseButton {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 3;
  }
  .alertDialogContainer {
    position: relative;
    flex: 1;
    padding: 0;
    height: 450px;
    width: 350px;
    box-sizing: border-box;
    border: 3px solid #e2658c;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
    @include media(mobile) {
      width: auto;
    }
    .alertDialogInner {
      padding: 50px 38px;
      width: 100%;
      height: 100%;
      .heading {
        margin: 0;
        font-size: 30px;
        font-weight: 900;
        text-align: left;
        line-height: 36px;
        color: #323232;
      }
      .copyText {
        margin: 0;
        width: 275px;
        color: #323232;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: left;
        a {
          color: $purple-primary;
          text-decoration: underline;
          &:hover {
            cursor: pointer;
          }
          &:visited {
            color: $purple-primary;
          }
          &:hover {
            color: $purple-secondary;
          }
        }
      }
      .doneButton {
        text-transform: initial;
        &.first {
          margin-bottom: 20px;
        }
      }
    }
  }
}
